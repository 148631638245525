<script setup>

import Footer from './components/Footer.vue'

import { useNatsStore } from './store/nats'
import { useKeycloakStore } from './store/auth'

const kcs = useKeycloakStore()
const natsStore = useNatsStore()

if (!kcs.authenticated) {
  kcs.login()
}

kcs.$subscribe((mutation, state) => {
  if (kcs.authenticated && !natsStore.connected) {
    natsStore.natsConn(kcs.nats)
  }
})

natsStore.$subscribe((mutation, state) => {
  if (state.connected && !state.subscribed) {
    natsStore.natsSub()
  }
})

</script>

<template>
  <div v-if="kcs.authenticated">
    <nav class="navbar navbar-expand-lg bg-dark">
      <div class="container-fluid">
        <router-link class="nav-link link-light" to="/">Kite Project</router-link>
        <button aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation"
                class="navbar-toggler"
                data-bs-target="#navbarNavDropdown" data-bs-toggle="collapse" type="button">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div id="navbarNavDropdown" class="collapse navbar-collapse">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item">
              <router-link class="nav-link link-light" to="/dashboard">Dashboard</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link link-light" to="/nats">NATS</router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <main>
      <div class="container-fluid">
        <router-view/>
      </div>
    </main>
  </div>
  <div v-else>
    <h1>Please logon...</h1>
  </div>
  <footer class="fixed-bottom">
    <div>
      <Footer/>
    </div>
  </footer>

</template>
