import { createRouter, createWebHistory } from 'vue-router'

import Home from '../components/Home.vue'
import Dashboard from '../components/Dashboard.vue'
import NatsClient from '../components/NatsClient.vue'

export default createRouter({
  history: createWebHistory(),
  linkActiveClass: '.active',
  routes: [
    {
      path: '/',
      component: Home
    },
    {
      path: '/dashboard',
      component: Dashboard
    },
    {
      path: '/nats',
      component: NatsClient
    }
  ]
})
