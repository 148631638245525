<script setup>
import { useNatsStore } from '../store/nats'
const natsStore = useNatsStore()
</script>

<template>

  <div v-if="natsStore.connected">
     <p class="text-success">Connected to {{ natsStore.getSever }}</p>
  </div>

</template>
