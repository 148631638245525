<script setup>
import { useNatsStore } from '../store/nats'

const natsStore = useNatsStore()

function formatMessage (msg) {
  const display = []
  let unixTime = null

  for (const [key, content] of Object.entries(msg.content)) {
    switch (content.type) {
      case 'string':
        display.push(key + ':' + content.value)
        break
      case 'timestamp':
        unixTime = new Date(content.value * 1)
        display.push(key + ':' + unixTime.toLocaleDateString('fr-CH') + ' ' + unixTime.toLocaleTimeString('fr-CH'))
        break
      default:
        display.push(key + ':' + content.value)
        break
    }
  }

  return display
}

</script>

<template>
  <div class='row gy-1'>
    <div v-for='[key, msg] in natsStore.messages' :key="key" class='col-sm-4'>
      <div class='card mb-3'>
        <div class='card-header'>
          {{ msg.title }}
        </div>
        <ul class='list-group list-group-flush'>
          <li v-for='value in formatMessage(msg)' :key="value" class='list-group-item'>
            {{ value }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
