<script setup>

import NatsMessage from './NatsMessage.vue'
import { useNatsStore } from '../store/nats'

const natsStore = useNatsStore()

</script>

<template>

  <div v-if="!natsStore.connected">
    <h3>Connecting server....</h3>
  </div>
  <div class="py-3">
    <NatsMessage />
  </div>

</template>
