<script setup>

import { useKeycloakStore } from '../store/auth'

const kcs = useKeycloakStore()

// if (!kcs.authenticated) {
//   kcs.login()
// }
</script>

<template>
  <div v-if="kcs.authenticated">
    <h3>Hello {{ kcs.user }}</h3>
  </div>
</template>
