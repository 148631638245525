import { defineStore } from 'pinia'
import {
  connect,
  usernamePasswordAuthenticator,
  StringCodec
} from 'nats.ws/cjs/nats'

export const useNatsStore = defineStore({
  id: 'nats',
  // state
  state: () => ({
    nc: null,
    connected: false,
    subscribed: false,
    messages: new Map([])
  }),
  // getters
  getters: {
    getSever: (state) => {
      if (state.connected) {
        return state.nc.getServer()
      } else {
        return ''
      }
    }
  },
  // actions
  actions: {
    async 'natsConn' (nats) {
      console.log('natsConn')
      console.log(`${nats.servers} - ${nats.user}`)
      const connOptions = {
        servers: nats.servers,
        authenticator: usernamePasswordAuthenticator(nats.user, nats.pass),
        debug: false
      }
      connect(connOptions)
        .then((nc) => {
          console.log('*********** Connected!***************')
          this.nc = nc
          this.connected = true
        })
        .catch((error) => {
          this.connected = false
          console.log(
            '************** Connection error! **********************'
          )
          console.log(error)
        })
    },
    async 'natsSub' () {
      if (!this.connected) {
        console.log('Not connected...')
        return
      }
      if (this.subscribed) {
        console.log('Already subscribed...')
        return
      }
      const sc = StringCodec()
      const sub = this.nc.subscribe('kite-dev.human')
      this.subscribed = true

      for await (const m of sub) {
        if (m.data) {
          console.log('Data received...')
          const data = sc.decode(m.data)
          try {
            const item = JSON.parse(data)
            if (item.id === undefined) {
              this.messages.set('void', item)
            } else {
              this.messages.set(item.id, item)
            }
          } catch (e) {
            const item = {}
            item.title = 'GLOBAL'
            item.properties = {}
            item.properties.raw = 'string'
            item.data = {}
            item.data.raw = data
            this.messages.set('void', item)
          }
        }
      }
      await this.nc.closed()
      this.subscribed = false
    }
  }
})
