import { defineStore } from 'pinia'
import Keycloak from 'keycloak-js'

export const useKeycloakStore = defineStore('keycloak', {
  state: () => ({
    kc: null,
    authenticated: false,
    nats: null,
    user: ''
  }),
  getters: {},
  actions: {
    login: async function () {
      this.kc = new Keycloak({
        url: import.meta.env.VITE_OIDC_URL,
        realm: import.meta.env.VITE_OIDC_APPLICATION_ID,
        clientId: import.meta.env.VITE_OIDC_CLIENT_ID
      })
      this.kc
        .init({
          onLoad: 'login-required',
          // onLoad: 'check-sso',
          redirectUri: import.meta.env.VITE_REDIRECT_URI,
          scope: 'kite-human'
        })
        .then((authenticated) => {
          setInterval(() => {
            this.kc
              .updateToken(15)
              .then((refreshed) => {
                if (refreshed) {
                  console.log('Token refreshed' + refreshed)
                }
              })
              .catch(() => {
                console.error('Failed to refresh token')
              })
          }, 60000)

          this.authenticated = authenticated
          this.nats = this.kc.tokenParsed ? this.kc.tokenParsed.nats : null
          this.user = this.kc.tokenParsed ? `${this.kc.tokenParsed.given_name} (${this.kc.tokenParsed.email})` : ''
        })
        .catch(() => {
          this.authenticated = false
        })
    }
  }
})
